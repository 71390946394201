.react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 1px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2.225em;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 1px;
}
/*.react-calendar button:enabled:hover {
    cursor: pointer;
    background-color: #df32c2;
}*/
.react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    background: rgb(240, 240, 240);
    color: rgb(0, 149, 61);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: rgb(240, 240, 240);
    color: rgb(0, 149, 61);
}
.react-calendar__tile--hasActive {
    background: rgb(220, 220, 220);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: rgb(220, 220, 220);
}
.react-calendar__tile--active {
    background: rgb(220, 220, 220);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: rgb(220, 220, 220);
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
