body,
html {
    height: 100%;
    margin: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "Draig-Bold";
    src: local("Draig-Bold"), url(./fonts/Draig-Bold.otf) format("otf");
}

@font-face {
    font-family: "Draig-Regular";
    src: local("Draig-Regular"), url(./fonts/Draig-Regular.otf) format("otf");
}

@font-face {
    font-family: "Quatro-Slab-Regular";
    src: local("Quatro-Slab-Regular"),
        url(./fonts/Quatro-Slab-Regular.ttf) format("ttf");
}
